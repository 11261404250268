<template>
  <div class="wrapper">
    <table class="table">
      <thead class="header">
        <th v-for="header in headers" :key="header">
          {{ header }}
        </th>
      </thead>
      <tbody>
        <tr v-for="item in tableItems" class="row" :key="item.id">
          <td v-for="header in headers" :class="getColumnClass(header, item)" @click="columnAction(item, header)"
            :key="header">
            <div class="drop-down-list" v-if="header === dropdownColumn">
              <select class="dropdownColumn" name="option" id="option"
                @change="$emit('dropdownSelect', [$event.target.value, item])">
                <option value="" selected>{{ dropdownText }}</option>
                <option v-for="option in dropdownOptions" v-bind:value="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div v-else-if="header === deleteColumn">
              <button class="deleteSVG">
                <img src="../../assets/trash.svg" alt="Delete" />
              </button>
            </div>
            <div v-else-if="header === radioToggleColumn">
              <RadioToggle
                :default-value="item[header]"
                :show-text="false"
                @selected="radioToggleSelected"
                :id="item[radioFieldToEmit]"
                :editable="radioEditable"
                :off-value="radioToggleOptions.get('off-value')"
                :on-value="radioToggleOptions.get('on-value')" />
            </div>
            <div v-else-if="header === copyColumn">
              <span>{{ item[header] }}</span> <img alt="copy" class="copy-icon" @click="copyCell" src="../../assets/copy.svg" />
            </div>
            <div v-else-if="header === cryptoColumn" class="crypto-column">
              <img :src='require(`./assets/${item[header]}.svg`)' alt="network"/> {{ item[header] }}
            </div>
            <div v-else>
              {{ item[header] }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import RadioToggle from "../RadioToggle/RadioToggle.vue";

export default defineComponent({
  name: "DataTable",
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    columnsToDisplay: {
      type: Array,
      required: true,
    },
    clickableColumn: {
      type: String,
    },
    copyColumn: {
      type: String,
      required: false,
    },
    dropdownColumn: {
      type: String,
      required: false,
    },
    dropdownOptions: {
      type: Array,
      required: false,
    },
    dropdownText: {
      type: String,
      required: false,
    },
    statusColumn: {
      type: String,
    },
    deleteColumn: {
      type: String,
    },
    statusCategories: {
      type: Map<string, string>,
    },
    radioToggleColumn: {
      type: String,
      reqired: false,
    },
    radioToggleOptions: {
      type: Map<string, string>,
      required: false,
    },
    radioEditable: {
      type: Boolean,
      default: true,
    },
    radioFieldToEmit: {
      type: String,
      required: false,
      default: 'id',
    },
    cryptoColumn: {
      type: String,
      required: false,
      validator: (val: string) =>
        ["USDC", "USDT", "ETH", "BTC"].includes(val),
    }
  },
  emits: ["viewRow", "dropdownSelect", "delete", "radioToggleSelected", "copy"],
  components: {
    RadioToggle,
  },
  setup(props, { emit }) {
    const tableItems = reactive<any[]>(props.tableData);
    const headers = props.columnsToDisplay as string[];
    const columnNumber = headers.length;
    const openDropdown = ref(false);

    const copyCell = (evt: any) => {
      const img = evt.target;
      const cell = img.parentNode;
      const span = cell.firstChild;
      emit('copy', span.textContent);
    }

    const columnAction = (item: any, header: string) => {
      switch (header) {
        case props.clickableColumn:
          emit("viewRow", item);
          break;
        case props.dropdownColumn:
          openDropdown.value = true;
          break;
        case props.deleteColumn:
          emit("delete", item);
          break;
        default:
          break;
      }
    };

    const radioToggleSelected = (evt: {value: string, key: string}) => {
      emit('radioToggleSelected', evt);
    }

    const getColumnClass = (columnName: string, columnValue: string) => {
      if (
        props.clickableColumn !== undefined &&
        columnName === props.clickableColumn
      ) {
        return "clickable-column";
      }
      if (
        props.deleteColumn !== undefined &&
        columnName === props.deleteColumn
      ) {
        return "deleteColumn";
      }
      if (props.radioToggleColumn !== undefined && columnName === props.radioToggleColumn) {
        return "radioToggleColumn";
      }
      if (props.copyColumn !== undefined && columnName === props.copyColumn) {
        return "copy-column";
      }
      if (
        props.statusColumn &&
        columnName === props.statusColumn &&
        props.statusCategories
      ) {
        const status = columnValue[columnName as keyof {}];
        const baseStyle = props?.dropdownColumn
          ? "status-pill-with-dropdown"
          : "status-pill";

        switch (props.statusCategories.get(status)?.toLowerCase()) {
          case "green":
            return `${baseStyle} status-pill__green`;
          case "orange":
            return `${baseStyle} status-pill__orange`;
          case "red":
            return `${baseStyle} status-pill__red`;
          default:
            return "status-pill status-pill__default";
        }
      }
    };

    return {
      tableItems,
      headers,
      columnNumber,
      getColumnClass,
      openDropdown,
      columnAction,
      copyCell,
      radioToggleSelected,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/_include-media.scss";
@import "../../assets/scss/colors";

table {
  border-collapse: collapse;
  border: 1px solid $brand-grey-200;
  width: 100%;
  table-layout: inherit;
}

th,
td {
  padding: 23px;
  vertical-align: center;
}

th {
  text-align: left;
}

.wrapper {
  position: relative;
  overflow-x: scroll;
  white-space: nowrap;
}

.copy-icon {
  margin: 3px;
}

.wrapper table td:first-child {
  @include media("<=tablet") {
    position: sticky;
    left: 0;
    background: $brand-white;
    border-right: 1px solid $brand-grey-200;
  }
}

.wrapper table thead th:first-child {
  @include media("<=tablet") {
    position: sticky;
    left: 0;
    background: $brand-grey-50;
    border-right: 1px solid $brand-grey-200;
  }
}

.wrapper table tr:first-child {
  @include media("<=tablet") {
    position: sticky;
    left: 0;
    background: $brand-white;
    border-right: 1px solid $brand-grey-200;
  }
}

tr {
  border-bottom: 1px solid $brand-grey-200;
  vertical-align: center;
}

.header {
  box-sizing: border-box;
  padding: 16px 24px 16px 16px;
  gap: 8px;
  width: 60px;
  height: 44px;
  background: $brand-grey-50;
  border: 1px solid $brand-grey-200;
  border-radius: 8px 0 0 0;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $brand-grey-600;
}

.row {
  background: $brand-white;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.clickable-column {
  text-align: center;
  color: #4fa58c;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-left: 1px solid $brand-grey-200;
  cursor: pointer;
}

.copy-column {
  text-align: center;
  line-height: 20px;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

.crypto-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;

  > img {
    width: 30%;
    margin: 5px;
  }
}

.drop-down-list {
  border: 1px solid $brand-secondary-purple;
  padding-right: 10px;
  background-color: $brand-white;
  border-radius: 5px;
  width: 5rem;
  height: 40px;
}

.dropdownColumn {
  gap: 4px;
  align-content: space-around;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $brand-secondary-purple;
  border: none !important;
  cursor: pointer;
  height: 100%;
  width: calc(110%);
  background-color: transparent !important;
  outline: none !important;
  padding-left: 10px;
}

.deleteSVG {
  background: transparent;
  border: none;
  cursor: pointer;
}

.deleteColumn {
  border-left: 1px solid $brand-grey-200;
  width: 10px;
}

.select-background {
  padding: 12px 8px 12px 16px;
  gap: 10px;
  width: 209px;
  height: 48px;
  background: $brand-white;
}

.status-pill-with-dropdown {
  margin-top: 3.5ch;
  border-radius: 40px;
  font-weight: 500;
  width: fit-content;
  padding: 4px 8px;
  display: inline-block;
}

.status-pill {
  border-radius: 40px;
  font-weight: 500;
  margin-top: 2.2ch;
  width: fit-content;
  padding: 4px 8px;
  display: inline-block;

  @include media("<=tablet") {
    margin-top: 22%;
  }

  &__green {
    color: $brand-secondary-teal-500;
    background: $brand-secondary-teal-25;
  }

  &__orange {
    color: $brand-primary-yellow-600;
    background: $brand-primary-yellow-25;
  }

  &__red {
    color: $status-error;
    background: #ffe2e8;
  }

  &__default {
    color: #646363;
    background: #d3d1d1;
  }
}
</style>
