<template>
  <div class="asset-select-list-container">
    <div class="heading">
      <p>{{ category }}</p>
    </div>
    <div class="select-list-body">
      <VueNextSelect
          :closeOnSelect="true"
          :modelValue="defaultSelected"
          @update:modelValue="onOptionSelected"
          valueBy="value"
          :options="optionValues"
          style="font-family: Jost, sans-serif"
      >
        <template class="text" style="font-family: Jost, sans-serif" v-slot:label="{ selected }">
          <template style="font-family: Jost, sans-serif" v-if="selected">
            <div class="selected-asset-list-item">
              <img :src="require(`./assets/${selected.value}.svg`)" alt="Flag" />
              <span class="text">{{ selected.label }}</span>
            </div>
          </template>
          <template v-if="!selected">
            <span class="text-default"> {{ defaultText }}</span>
          </template>
        </template>
        <template class="text" v-slot:dropdown-item="{ option }">
          <div class="asset-list-item">
            <img :src="require(`./assets/${option.value}.svg`)" alt="asset" />
            <span class="text-main">{{ option.label }}</span>
          </div>
        </template>
      </VueNextSelect>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import VueNextSelect from "vue-next-select";
import "vue-next-select/dist/index.min.css";

export default defineComponent({
  name: "AssetSelectList",
  components: {
    VueNextSelect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: String,
    },
    defaultText: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      require: true,
    },
    type: {
      type: String,
      default: 'coin'
    }
  },
  emits: ["update:selected"],
  setup(props, { emit }) {
    const defaultSelected = ref(props.defaultValue ?? null);
    const selected = defaultSelected;
    const optionValues = ref<unknown[]>(props.options.filter((option: any) => option.type === props.type));

    const onOptionSelected = (evt: any) => {
      emit("update:selected", evt);
    };

    return {
      selected,
      defaultSelected,
      optionValues,
      onOptionSelected,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/_include-media.scss";
@import "../../assets/scss/_colors.scss";

.text {
  font-family: Jost, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 5px;

  &-default {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #bdbdbd;
    margin-left: 5px;
  }
}

.text-main {
  font-family: Jost, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.asset-select-list-container {
  & .select-container:focus {
    outline: none !important;
  }

  & .menu .select-list-body {
    width: 100% !important;
  }

  & .select-container {
    outline: none !important;
    display: flex;
    background: #ffffff;
    border-radius: 4px;
    flex: none;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #9e9e9e;

    @include media("<tablet") {
      height: 54px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }

  & .heading {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin: 0px -20px -6px 0px;

    @include media("<tablet") {
      font-weight: 700;
      font-size: 20px;
      line-height: 18px;
    }
  }

  & .vue-select {
    width: 100% !important;
    height: 100% !important;
    border-radius: 16px;
    border-color: #e0e0e0;

    &.direction-bottom {
      & .vue-dropdown {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        border-color: #e0e0e0;
      }
    }
  }

  & .vue-select-header {
    height: 54px;
    cursor: pointer !important;
    line-height: 18px;
    color: $brand-black;
    font-size: 16px;

    &>.vue-input {
      margin-left: 0px;
      cursor: pointer !important;
      font-weight: 500;
      color: $brand-grey-500 !important;
      font-size: 12px;

      &>.selected-asset-list-item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &>img {
          height: 100%;
          padding-left: 15px;
        }

        &>span {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 5px;
          color: $brand-black;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    &>.icon.arrow-downward {
      margin-right: 16px;
    }
  }

  & .vue-dropdown {
    z-index: 1000;

    &-item {
      font-family: Jost, "sans-serif";
      color: $brand-black;
      font-weight: 400;
      font-size: 12px !important;
      margin-left: 0px;
      height: 30px;
      line-height: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &>.asset-list-item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &>img {
          height: 100%;
        }

        &>span {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }

      &.highlighted {
        background-color: $brand-white !important;
      }

      &.selected {
        background-color: $brand-white !important;
      }

      &:hover {
        background-color: $brand-grey-300 !important;
      }
    }
  }
}

.asset-list-item {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &>img {
    height: 90%;
    padding-left: 10px;
  }

  &>span {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
}
</style>
