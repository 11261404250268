<template>
  <div class="yc--password--prompt">
    <div
      v-for="(rule, index) in rules"
      :key="`yc--password--prompt-rule-${index}`"
      class="yc--password--prompt_item"
      :class="{
        'yc--password--prompt_enabled': rule.enabled,
      }"
    >
      <img src="../../assets/check-circle.svg" alt="check-circle" />
      {{ rule.label }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PasswordPrompt",
  props: {
    rules: {
      type: Array,
      default: [
        { enabled: true, label: "At least 8 characters" },
        { enabled: false, label: "Uppercase letter (A-Z)" },
        { enabled: false, label: "Lowercase letter (a-z)" },
        { enabled: true, label: "Special character (#,*)" },
        { enabled: true, label: "Number (0-9)" },
      ],
    },
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/colors";

.yc--password--prompt {
  font-family: "Jost", sans-serif;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.3rem;

  &_item {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    border-radius: 45px;
    color: $brand-grey-700;
    background-color: $brand-grey-200;

    img {
      margin-right: 5px;
    }
  }

  &_enabled {
    background-color: $brand-primary-yellow-25;
  }
}
</style>
