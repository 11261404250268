<template>
  <div class="overlay">
    <div v-if="active" class="menu">
      <div v-for="items in filterData">
        <DropDownList
          v-model:selected="items.filter"
          :options="items.options"
          :default-value="items.filter"
          :category="items.name"
        />
      </div>
      <div class="container-button">
        <Button
          text="Cancel"
          :disabled="false"
          color="purple"
          variant="outline"
          @buttonClicked="cancel()"
          style="width: 49%; height: 40px; border-radius: 4px"
        />
        <Button
          text="Apply"
          :disabled="false"
          color="purple"
          variant="fill"
          @buttonClicked="apply()"
          style="width: 49%; height: 40px; border-radius: 4px"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import DropDownList from "../DropDownList/DropDownList.vue";
import Button from "../Button/Button.vue";

interface filterData {
  name: string;
  options: string[];
  filter: string;
}

export default defineComponent({
  name: "DropDownCard",
  emits: ["cancelClicked", "applyClicked"],
  props: {
    filterData: {
      type: Array as PropType<Array<filterData>>,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DropDownList,
    Button,
  },
  setup(props, { emit }) {
    const filterData = ref(props.filterData);
    const apply = () => {
      emit("applyClicked", filterData);
    };

    const cancel = () => {
      filterData.value.map((element: filterData) => {
        element.filter = "";
      });
      emit("cancelClicked", filterData);
    };

    return {
      filterData,
      apply,
      cancel,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/_include-media.scss";

.overlay {
  position: absolute;
  z-index: 1;
  right: 0;
  @include media("<tablet") {
  }
}

.menu {
  padding: 24px 16px;
  gap: 24px;
  width: 235px;
  min-height: 130px;
  left: 72px;
  background: #ffffff;
  box-shadow:
    0px 20px 24px rgba(0, 0, 0, 0.08),
    0px 8px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;

  .select-list-body {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    padding-right: 10%;
  }

  @include media("<tablet") {
    visibility: visible;
    z-index: 1;
    position: fixed;
    width: 83%;
    height: 59%;
    left: 32px;
    top: 33%;
    box-shadow:
      36px 26px 29px 23px rgba(0, 0, 0, 0.08),
      51px 74px 78px 1000px rgba(0, 0, 0, 0.25);
  }
}

.container-button {
  padding-top: 25px;
  gap: 1.3rem;
  display: flex;
}
</style>
