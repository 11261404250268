<template>
  <transition v-if="open" name="modal-fade" class="yc--dialog">
    <div
      id="modal"
      class="yc--dialog__overlay"
      :style="overlayStyle"
      @click="() => (overlayCloseClick ? $emit('close-dialog') : void 0)"
      tabindex="0"
      @keyup.esc="$emit('close-dialog')"
    >
      <div class="yc--dialog__content" :style="contentStyles" @click.stop>
        <div v-if="icon" class="yc--dialog__icon">
          <img :src="icon" alt="" />
        </div>

        <div class="yc--dialog__header">
          <h5 v-if="title" class="yc--dialog__title" :style="headerStyle">{{ title }}</h5>
          <p v-if="subtitle" class="yc--dialog__subtitle">{{ subtitle }}</p>

          <a
            v-if="showCloseButton"
            class="yc--dialog__close-action"
            @click="$emit('close-dialog')"
          >
            <img src="../../assets/close-alt.svg" alt="close image" />
          </a>
        </div>

        <div class="yc--dialog__body" :style="bodyStyle">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "Dialog",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    width: {
      type: Number,
      default: 350,
    },
    overlayCloseClick: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    overlayStyle: {
      type: String,
    },
    contentStyle: {
      type: String,
    },
    headerStyle: {
      type: String,
    },
    bodyStyle: {
      type: String,
    },
  },
  watch: {
    open: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          document.getElementById("modal")?.focus();
        }, 100);
      }
    },
  },
  setup(props) {
    let contentStyles = ref(`width: ${props.width}px; ${props.contentStyle}`);
    let headerStyle = ref(`${props.headerStyle}`);

    onMounted(() => {
      (document.querySelector("body") as HTMLBodyElement).style.overflow =
        "hidden";
    });

    return {
      contentStyles,
      headerStyle,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/colors";
@import "../../assets/scss/_include-media.scss";

.yc--dialog {
  font-family: "Jost", sans-serif;

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1001;
    justify-content: center;
    align-items: center;
    padding: 0;
    overflow: auto;
    text-align: left;
  }

  &__content {
    width: 100%;
    position: relative;
    background-color: $brand-white;
    border-radius: 8px;
    padding: 20px;
    font-family: "Jost", sans-serif;
  }

  &__header {
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 21px;
    color: $brand-black !important;
    margin-bottom: 0;
    margin-top: 15px;
  }

  &__subtitle {
    color: $brand-black !important;
    margin-bottom: 15px;
    color: $brand-black;
  }

  &__close-action {
    position: absolute;
    top: 20px;
    right: 20px;
    left: unset;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;
    cursor: pointer;
    background: transparent;

    img {
      height: 15px;
    }
  }

  &__icon {
    text-align: center;
    padding: 40px 0 0 0;

    img {
      display: inline-block !important;
    }
  }

  .modal-fade-tranistion {
    transition: opacity 0.5s ease;
  }

  .modal-fade-enter {
    opacity: 0;
  }

  .modal-fade-leave {
    opacity: 0;
  }

  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity 0.5s ease;
  }
}
</style>
