<template>
  <div class="paginator-box">
    <div class="text-box">
      {{ total }} Results
      <span class="dot"></span>
      Page {{ selected }} of {{ lastPage }}
    </div>
    <div class="paginator-buttons">
      <button
        class="arrow-button"
        type="button"
        @click="back"
        :disabled="selected === 1"
      >
        <svg
          class="arrow-svg"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2266 5.5C11.2266 5.92188 10.8984 6.25 10.5 6.25H3.30469L5.76562 8.73438C6.07031 9.01562 6.07031 9.50781 5.76562 9.78906C5.625 9.92969 5.4375 10 5.25 10C5.03906 10 4.85156 9.92969 4.71094 9.78906L0.960938 6.03906C0.65625 5.75781 0.65625 5.26562 0.960938 4.98438L4.71094 1.23438C4.99219 0.929688 5.48438 0.929688 5.76562 1.23438C6.07031 1.51562 6.07031 2.00781 5.76562 2.28906L3.30469 4.75H10.5C10.8984 4.75 11.2266 5.10156 11.2266 5.5Z"
            fill="#424242"
          />
        </svg>
      </button>

      <div
        v-if="!spreadPaginator"
        v-for="page in pagesArray"
        class="paginator-container"
      >
        <div class="paginator-button-container">
          <button
            :class="selected === page ? 'active-field' : 'inactive-field'"
            :disabled="selected === page"
            @click="pageButtonClicked(page)"
          >
            {{ page }}
          </button>
        </div>
      </div>

      <div v-if="spreadPaginator" class="paginator-container">
        <div class="paginator-button-container">
          <button
            :class="selected === page1 ? 'active-field' : 'inactive-field'"
            :disabled="selected === page1"
            @click="pageButtonClicked(page1)"
          >
            {{ page1 }}
          </button>
        </div>
        <div class="paginator-button-container">
          <button
            :class="selected === page2 ? 'active-field' : 'inactive-field'"
            @click="pageButtonClicked(page2)"
          >
            {{ page2 }}
          </button>
        </div>
        <div class="paginator-button-container">
          <button
            :class="selected === page3 ? 'active-field' : 'inactive-field'"
            @click="pageButtonClicked(Number(page3))"
          >
            {{ page3 }}
          </button>
        </div>
        <div class="paginator-button-container">
          <button
            :class="selected === page4 ? 'active-field' : 'inactive-field'"
            @click="pageButtonClicked(Number(page4))"
          >
            {{ page4 }}
          </button>
        </div>
        <div class="paginator-button-container">
          <button
            :class="selected === lastPage ? 'active-field' : 'inactive-field'"
            @click="pageButtonClicked(Number(lastPage))"
            :disabled="selected === lastPage"
          >
            {{ lastPage }}
          </button>
        </div>
      </div>
      <button
        class="arrow-button"
        type="button"
        @click="forward"
        :disabled="selected === lastPage"
      >
        <svg
          class="arrow-svg"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0156 6.03906L7.26562 9.78906C7.125 9.92969 6.9375 10 6.75 10C6.53906 10 6.35156 9.92969 6.21094 9.78906C5.90625 9.50781 5.90625 9.01562 6.21094 8.73438L8.67188 6.25H1.5C1.07812 6.25 0.75 5.92188 0.75 5.5C0.75 5.10156 1.07812 4.75 1.5 4.75H8.67188L6.21094 2.28906C5.90625 2.00781 5.90625 1.51562 6.21094 1.23438C6.49219 0.929688 6.98438 0.929688 7.26562 1.23438L11.0156 4.98438C11.3203 5.26562 11.3203 5.75781 11.0156 6.03906Z"
            fill="#424242"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import Button from "../Button/Button.vue";

export default defineComponent({
  name: "Paginator",
  props: {
    rowTotal: {
      type: Number,
      required: true,
    },
  },
  components: {
    Button,
  },
  emits: ["pageChange"],
  setup(props, { emit }) {
    const firstPage = ref(0);
    const disabled = ref(true);
    const selected = ref(1);

    const total = computed(() => {
      disabled.value = true;
      firstPage.value = 0;
      selected.value = 1;
      return props.rowTotal;
    });
    const pages = computed(() => Math.ceil(total.value / 10));
    const pagesArray = computed(() =>
      Array.from({ length: pages.value }, (_, i) => i + 1),
    );
    const indexArray = computed(() =>
      Array.from({ length: pages.value }, (_, i) => i * 10),
    );
    const spreadPaginator = computed(() => {
      if (pages.value <= 5) {
        return false;
      } else {
        return true;
      }
    });
    const page1 = computed(() => pagesArray.value[firstPage.value]);
    const page2 = computed(() => pagesArray.value[1 + firstPage.value]);
    const page4 = computed(() => pagesArray.value[pagesArray.value.length - 2]);
    const lastPage = computed(
      () => pagesArray.value[pagesArray.value.length - 1],
    );
    const page3 = computed(() => {
      if (pagesArray.value.length > 3) {
        if (lastPage.value - pagesArray.value[firstPage.value] > 4) {
          disabled.value = true;
          return "...";
        } else {
          disabled.value = false;
          return pagesArray.value[2 + firstPage.value];
        }
      }
    });

    const selectPage = (page: number) => {
      emit("pageChange", indexArray.value[page - 1]);
      selected.value = page;
    };

    const forward = () => {
      emit("pageChange", indexArray.value[selected.value]);
      if (
        spreadPaginator.value &&
        lastPage.value - pagesArray.value[firstPage.value] > 4
      ) {
        firstPage.value += 1;
        selected.value += 1;
      } else {
        selected.value += 1;
      }
    };

    const pageButtonClicked = (page: number) => {
      emit("pageChange", indexArray.value[page - 1]);
      if (page > selected.value) {
        firstPage.value += page - selected.value;

        if (
          spreadPaginator.value &&
          lastPage.value - pagesArray.value[firstPage.value] > 4
        ) {
          selected.value = page;
        } else {
          firstPage.value = lastPage.value - 5;
          selected.value = page;
        }
      } else {
        if (page > page1.value) {
          firstPage.value = page1.value - 1;
        } else {
          firstPage.value = page1.value - 2;
        }

        if (firstPage.value < 0) {
          firstPage.value = 0;
        }
        selected.value = page;
      }
    };

    const back = () => {
      emit("pageChange", indexArray.value[selected.value - 2]);
      if (
        spreadPaginator &&
        (lastPage.value - pagesArray.value[firstPage.value] > 4 ||
          selected.value === lastPage.value - 4)
      ) {
        firstPage.value -= 1;
        selected.value -= 1;
      } else {
        selected.value -= 1;
      }
    };
    return {
      selectPage,
      forward,
      page1,
      page2,
      back,
      page4,
      lastPage,
      page3,
      disabled,
      selected,
      firstPage,
      pages,
      pagesArray,
      spreadPaginator,
      total,
      pageButtonClicked,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/colors";

.text-box {
  float: left;
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: nowrap;
  align-items: center;
  height: 51px;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $brand-grey-600;
  padding-left: 10px;
}

.dot {
  height: 4px;
  width: 4px;
  background: $brand-grey-600;
  border-radius: 50%;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}

.paginator-box {
  background: $brand-white;
  height: 72px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paginator-buttons {
  float: right;
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: nowrap;
  align-items: center;
}

.arrow-button {
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: transparent;
  width: 40px;
  height: 54px;

  font-family: "Font Awesome 6 Pro", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 18px;
}

.arrow-svg {
  width: 17px;
}

.paginator-text {
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $brand-grey-800;
}

.active-field {
  font-family: Jost, sans-serif;
  font-style: normal;
  text-align: center;
  color: $brand-white;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid $brand-secondary-purple !important;
  background: $brand-secondary-purple !important;
  padding: 0px;
  width: 40px;
  height: 40px;
}

.inactive-field:disabled {
  font-family: Jost, sans-serif;
  font-style: normal;
  text-align: center;
  color: $brand-grey-800;
  background-color: white;
  border: none;
  width: 40px;
  height: 40px;
}

.inactive-field:enabled {
  font-family: Jost, sans-serif;
  font-style: normal;
  text-align: center;
  color: $brand-grey-800;
  background-color: white;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.paginator-button-container {
  display: flex;
  align-content: center;
  align-items: flex-end;
  flex-direction: row;
  height: 80%;
  flex-wrap: wrap;
}

.paginator-container {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 80%;
}
</style>
