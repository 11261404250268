<template>
  <div class="widget-input">
    <div id="widgetInput" class="widget-input-container">
      <input
        :type="type"
        :name="name"
        v-model="input"
        :placeholder="placeholder"
        :class="{
          'widget-input-input': true,
          'widget-input-error-border': error,
        }"
        :disabled="disabled"
        :readonly="readonly"
        @focus="$emit('focus', true)"
        @input="$emit('update:modelValue', input)"
      />
    </div>
    <div v-if="error">
      <span class="widget-input-error-text">
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "WidgetInput",
  components: {},
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator: (val: string) => ["text", "number"].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Function,
      default: () => true,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "focus"],
  setup(props) {
    let error = ref(undefined);
    let input = ref(props.value);

    watch(input, (value) => {
      const ruleError = props.rules(value || "");
      ruleError === true
        ? (error.value = undefined)
        : (error.value = ruleError);

      if (!value && ruleError === true) {
        error.value = undefined;
      }
    });
    return {
      error,
      input,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/colors";

#widgetInput ::placeholder {
  color: #bdbdbd;
  font-family: Jost, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.widget-input {
  position: relative;
  text-align: left;
  flex-direction: row;
  align-items: flex-start;
  background: #ffffff;
  font-family: "Jost", sans-serif;

  &-container {
    position: relative;
  }

  &-input {
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    transition: border 500ms;
    padding: 20px 16px 20px 16px;
    font-weight: 400;
    color: #000000;
    font-size: 16px;
    font-family: "Jost", sans-serif;
  }

  &-input:focus {
    outline: none;
    border: 1px solid #e0e0e0;
  }

  &-error-text {
    width: 360px;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #b00020;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  &-error-border {
    border: 1px solid #b00020 !important;
    border-radius: 12px;
  }
}
</style>
