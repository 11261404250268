<template>
  <Popper hover arrow :content="content" :placement="position" class="black">
    <slot></slot>
  </Popper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popper from "vue3-popper";

export default defineComponent({
  name: "ToolTip",
  props: {
    content: {
      type: String,
      required: true,
      default: "",
    },
    position: {
      type: String,
      default: "bottom",
      required: false,
    },
  },
  components: {
    Popper,
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/colors";

:root {
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 8px;
  --popper-theme-padding: 8px;
  --popper-theme-box-shadow: 0 6px 10px -6px rgba(0, 0, 0, 0.25);
}

.popper {
  max-width: 200px;
  font-family: "Jost";
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: $brand-white !important;
  background-color: $brand-grey-800 !important;
}

.popper:hover {
  background-color: $brand-grey-800 !important;
}

.popper > #arrow::before {
  background-color: $brand-grey-800 !important;
}
</style>
