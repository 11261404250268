<template>
  <div class="yc--search--input">
    <div id="container" class="yc--search--input__input-container">
      <input
        :id="name"
        :name="name"
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :class="{ 'yc--search--input__input': true }"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        :aria-disabled="disabled"
        :aria-readonly="readonly"
        :aria-required="required"
        @blur="$emit('blur', true)"
        @focus="$emit('focus', true)"
        @keydown="startSearch"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SearchInput",
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue", "blur", "focus", "startSearch"],
  setup(props, context) {
    const startSearch = (e: any) => {
      // fire event on "enter" key press
      if (e.keyCode === 13) {
        context.emit("startSearch", true);
      }
    };

    return {
      startSearch,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/colors";

.yc--search--input {
  position: relative;
  text-align: left;
  flex-direction: row;
  align-items: flex-start;
  background: #ffffff;

  &__input-container {
    position: relative;
  }

  &__input {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    transition: border 500ms;
    font-family: "Jost", sans-serif;
    padding: 6px;
    padding-left: 44px;
    font-weight: 400;
    color: #000000;
    font-size: 16px;
    line-height: 22px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1Ljc1IDE0LjcxODhMMTEuNTYyNSAxMC41MzEyQzEyLjQ2ODggOS40Mzc1IDEyLjk2ODggOC4wMzEyNSAxMi45Njg4IDYuNUMxMi45Njg4IDIuOTM3NSAxMC4wMzEyIDAgNi40Njg3NSAwQzIuODc1IDAgMCAyLjkzNzUgMCA2LjVDMCAxMC4wOTM4IDIuOTA2MjUgMTMgNi40Njg3NSAxM0M3Ljk2ODc1IDEzIDkuMzc1IDEyLjUgMTAuNSAxMS41OTM4TDE0LjY4NzUgMTUuNzgxMkMxNC44NDM4IDE1LjkzNzUgMTUuMDMxMiAxNiAxNS4yNSAxNkMxNS40Mzc1IDE2IDE1LjYyNSAxNS45Mzc1IDE1Ljc1IDE1Ljc4MTJDMTYuMDYyNSAxNS41IDE2LjA2MjUgMTUuMDMxMiAxNS43NSAxNC43MTg4Wk0xLjUgNi41QzEuNSAzLjc1IDMuNzE4NzUgMS41IDYuNSAxLjVDOS4yNSAxLjUgMTEuNSAzLjc1IDExLjUgNi41QzExLjUgOS4yODEyNSA5LjI1IDExLjUgNi41IDExLjVDMy43MTg3NSAxMS41IDEuNSA5LjI4MTI1IDEuNSA2LjVaIiBmaWxsPSIjOUU5RTlFIi8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    background-position: top 15px left 15px;

    &::placeholder {
      color: #9e9e9e;
    }
  }

  &__input:focus {
    outline: none;
    border: 1px solid $brand-secondary-purple;
  }
}
</style>
