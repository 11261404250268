<template>
  <Transition :enter-active-class="transition.enter" :leave-active-class="transition.leave">
    <div v-if="isActive" class="yc--info-snackbar" :class="snackposition">
      <div role="alert" class="yc--info-snackbar__item" :class="[
        `yc--info-snackbar__item--${position}`,
      ]" @mouseover="toggleTimer(true)" @mouseleave="toggleTimer(false)" @click="whenClicked">
        <div class="yc--info-snackbar__icons">
          <img src="../../assets/circleInfo.svg" />
          <img src="../../assets/greenClose.svg" @click="whenButtonClicked"/>
        </div>
        <div class="yc--info-snackbar__text">{{ text }}</div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";

class Timer {
  delay: number;
  timer: number;
  startedAt: number;
  callback: any;

  constructor(callback: TimerHandler, delay: number) {
    this.startedAt = Date.now();
    this.callback = callback;
    this.delay = delay;
    this.timer = setTimeout(callback, delay);
  }

  pause() {
    this.stop();
    this.delay -= Date.now() - this.startedAt;
  }

  resume() {
    this.stop();
    this.startedAt = Date.now();
    this.timer = setTimeout(this.callback, this.delay);
  }

  stop() {
    clearTimeout(this.timer);
  }
}

export default defineComponent({
  name: "Snackbar",
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: "",
    },
    position: {
      type: String,
      default: "bottom",
      validator(value: string) {
        return [
          "top",
          "bottom",
          "top-right",
          "bottom-right",
          "top-left",
          "bottom-left",
        ].includes(value);
      },
    },
    duration: {
      type: Number,
      default: 5000,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    onDismiss: {
      type: Function,
      default: () => { },
    },
    onClick: {
      type: Function,
      default: () => { },
    },
    onButtonClick: {
      type: Function,
      default: () => { },
    },
    pauseOnHover: {
      type: Boolean,
      default: true,
    },
  },
  setup(
    props: {
      position: string;
      duration: number;
      dismissible: boolean;
      pauseOnHover: boolean;
      onClick: any;
      onDismiss: any;
      onButtonClick: any;
    },
    context,
  ) {
    const isActive = ref(false);
    const isHovered = ref(false);
    const type = ref("default");
    const timer = ref<any>(null);

    const transition = computed(() => {
      switch (props.position) {
        case "top":
        case "top-right":
        case "top-left":
          return {
            enter: "yc--info-snackbar--fade-in-down",
            leave: "yc--info-snackbar--fade-out-up",
          };
        case "bottom":
        case "bottom-right":
        case "bottom-left":
          return {
            enter: "yc--info-snackbar--fade-in-up",
            leave: "yc--info-snackbar--fade-out-down",
          };
        default:
          return {
            enter: "",
            leave: "",
          };
      }
    });

    const snackposition = computed(() => {
      switch (props.position) {
        case "top":
        case "top-right":
        case "top-left":
          return "yc--info-snackbar--top";
        case "bottom":
        case "bottom-right":
        case "bottom-left":
          return "yc--info-snackbar--bottom";
        default:
          return "yc--info-snackbar--custom-position";
      }
    });

    function dismiss() {
      if (timer.value) timer.value.stop();
      isActive.value = false;
      // Timeout for the animation complete before destroying
      setTimeout(function () {
        props.onDismiss.apply(null, arguments);
        context.emit("dismissed");
      }, 2000);
    }

    const showNotice = () => {
      isActive.value = true;
      if (props.duration) {
        timer.value = new Timer(dismiss, props.duration);
      }
    };

    function whenClicked() {
      if (!props.dismissible) return;
      props.onClick.apply(null, arguments);
      dismiss();
    }

    function whenButtonClicked() {
      if (!props.dismissible) return;
      props.onButtonClick.apply(null, arguments);
      dismiss();
    }

    const toggleTimer = (newVal: boolean) => {
      if (!props.pauseOnHover) return;
      newVal ? timer.value.pause() : timer.value.resume();
    };

    onMounted(() => {
      showNotice();
    });

    return {
      type,
      isActive,
      isHovered,
      transition,
      snackposition,
      dismiss,
      toggleTimer,
      whenClicked,
      whenButtonClicked,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/colors";

$snackbar-colors: (
  ) !default;
$snackbar-colors: map-merge(("default": $brand-grey-800,
    ),
    $snackbar-colors
);

.snackbar-button {
  background: transparent;
  border: none;
  outline: none;
  color: $brand-primary-yellow;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0 16px 0 0;
  width: 62px;
  height: 24px;
  cursor: pointer;
  font-family: "Jost", sans-serif;
}

.yc--info-snackbar {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em;
  overflow: hidden;
  z-index: 9999999;
  pointer-events: none;
  font-family: "Jost", sans-serif;

  &__icons {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    margin: 5%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    animation-duration: 150ms;
    margin: 0.5rem;
    background: $brand-grey-800;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    pointer-events: auto;
    color: $brand-white;
    width: 344px;
    min-height: 52px;
    max-height: 200px;
    cursor: pointer;
    background-color: $brand-secondary-teal-25;
    border: 1px solid $brand-secondary-teal-600;

    // Individual snackbar position
    &.yc--info-snackbar__item--top,
    &.yc--info-snackbar__item--bottom {
      align-self: center;
    }

    &.yc--info-snackbar__item--top-right,
    &.yc--info-snackbar__item--bottom-right {
      align-self: flex-end;
    }

    &.yc--info-snackbar__item--top-left,
    &.yc--info-snackbar__item--bottom-left {
      align-self: flex-start;
    }
  }

  &__text {
    margin: 0;
    padding: 0.5em 1em;
    word-break: break-word;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $brand-secondary-teal-700;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    position: fixed !important;
  }
}

.yc--info-snackbar--top {
  flex-direction: column;
}

.yc--info-snackbar--bottom {
  flex-direction: column-reverse;
}

.yc--info-snackbar--custom-position {
  position: absolute;
}

//Fade in down
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.yc--info-snackbar--fade-in-down {
  animation-name: fadeInDown;
  animation-duration: 1s;
}

//Fade out down
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.yc--info-snackbar--fade-out-down {
  animation-name: fadeOutDown;
  animation-duration: 1s;
}

//Fade in up
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.yc--info-snackbar--fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 1s;
}

// Fade out up
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.yc--info-snackbar--fade-out-up {
  animation-name: fadeOutUp;
  animation-duration: 1s;
}
</style>
