<template>
  <div class="yc--code--input">
    <ul
      class="yc--code--input__redeem_input"
      :class="`${[amount === 6 ? 'short-input' : '']}`"
    >
      <li v-for="index in amount" :key="index">
        <input
          ref="codeInput"
          required
          type="tel"
          onkeydown="return event.keyCode !== 190"
          placeholder=" "
          class="yc--code--input__codeInput"
          :class="{
            'yc--code--input__large': size === 'large',
            'yc--code--input__passwordmask': type === 'password',
            'yc--code--input__input': true,
          }"
          :maxlength="1"
          :disabled="focused - 1 > pin.length"
          @input="triggerEvent"
          @keydown.right.prevent="moveRight"
          @keydown.left.prevent="moveLeft"
          @keydown.delete.prevent="backspace"
          @paste="onPaste"
          @keypress="isNumber($event)"
          @focus="focusInput(index)"
          @blur="focused = 0"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, watch, defineComponent } from "vue";

export default defineComponent({
  name: "CodeInput",
  props: {
    size: {
      type: String,
      default: "small",
    },
    type: {
      type: String,
      default: "password",
    },
    rules: {
      type: Function,
      default: () => true,
    },
    amount: {
      required: true,
      type: Number,
      default: 6,
    },
  },
  setup(
    props: {
      size: string;
      type: string;
      rules: Function;
      amount: number;
    },
    { emit },
  ) {
    const pin = ref("");
    const error = ref(undefined);
    const focused = ref(0);
    const codeInput = ref(null);

    const isNumber = (evt: any) => {
      evt = evt || window.event;
      const charCode = evt.which || evt.keyCode;
      const key = evt.key;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 8
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    };

    const moveRight = () => {
      focused.value = Math.min(focused.value + 1, props.amount);
    };

    const moveLeft = () => {
      focused.value = Math.max(focused.value - 1, 0);
    };

    const emitPin = () => {
      // Validate the input based on the rules prop
      const err = props.rules(pin.value || "");
      error.value = err === true ? undefined : err;
      emit("update:modelValue", pin.value);
    };

    const backspace = () => {
      if (focused.value === 1) focused.value = 1;
      if ((document.activeElement as HTMLInputElement).value) {
        (document.activeElement as HTMLInputElement).value = "";
      }
      focused.value = Math.max(focused.value - 1, 0);
      if (typeof pin.value !== "object") {
        pin.value = pin.value.substring(pin.value.length - 1, 0);
        emitPin();
      }
    };

    const onPaste = (e: any) => {
      // Stop data actually being pasted into div
      e.stopPropagation();
      e.preventDefault();

      const clipboardData = e.clipboardData || (window as any).clipboardData;
      const pastedData = clipboardData.getData("Text");

      const digits = `${pastedData}`.split("");
      if (codeInput.value) {
        (codeInput.value as any).forEach((_ref: any, index: number) => {
          if (digits[index]) _ref.value = digits[index];
        });
      }

      pin.value = pastedData;
      focused.value = props.amount;
      emitPin();
    };

    const focusInput = (index: number) => {
      focused.value = index;
    };

    const triggerEvent = () => {
      if (codeInput.value) {
        pin.value = (codeInput.value as any)
          .reduce((total: number, input: any) => total + input.value, 0)
          .substring(1);

        moveRight();
        emitPin();
      }
    };

    watch(focused, (value) => {
      if (codeInput.value && value > 0) {
        (codeInput.value[value - 1] as HTMLInputElement).focus();
        (codeInput.value[value - 1] as HTMLInputElement).select();
      }
    });

    watch(pin, (value) => {
      if (value.length === 0) {
        (codeInput.value as any).forEach((_ref: any) => {
          _ref.value = "";
        });
      } else if (value.length === 16) {
        (codeInput.value as any).forEach((_ref: any, index: number) => {
          _ref.value = value[index];
        });
      }
    });

    onMounted(() => (focused.value = 1));

    return {
      pin,
      error,
      focused,
      codeInput,
      isNumber,
      moveRight,
      moveLeft,
      backspace,
      onPaste,
      focusInput,
      triggerEvent,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/colors";
@import "../../assets/scss/include-media";

.yc--code--input {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;

  &__redeem_input {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    padding-bottom: 0px !important;
    list-style: none;
    width: auto;
    justify-content: center;
  }

  &__code-input {
    height: 50px;
    overflow-y: hidden;
    display: -webkit-inline-box;
  }

  &__codeInput {
    border: 1px solid;
    width: 48px;
    height: 48px;
    background: #ffffff;
    border: 1px solid $brand-grey-400;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 14;
    line-height: 22;
    color: $brand-black;
  }

  &__large {
    width: 60px !important;
    height: 60px !important;
    font-size: 22px !important;
  }

  &__codeInput:required:valid {
    background: $brand-white;
    color: $brand-black;
    font-weight: 500;
    font-size: 16px;
  }

  li {
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  li:last-child {
    span {
      visibility: hidden;
      display: none;
    }
  }

  &__valid {
    background: white;
    color: $brand-black;
    font-weight: 500;
    font-size: 500;
  }

  .short-input {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
  }

  @include media(">=phone", "<tablet") {
    &__code-input {
      overflow-x: scroll;
      justify-content: flex-start;
    }

    .zoomInput:focus-within {
      &__codeInput {
        width: 60px;
        height: 80px;
        font-size: 22px;
      }
    }
  }

  @include media(">=tablet") {
    &__code-input {
      overflow-x: auto !important;
      justify-content: center !important;
    }
  }

  &__input:focus {
    outline: none;
    border: 1px solid $brand-secondary-purple;
  }

  &__error-msg {
    font-size: 12;
    font-weight: 400;
    color: $status-error;
    flex-basis: 100%;
    padding-left: 10%;
    text-align: center;
  }

  &__label {
    width: 100%;
    color: $brand-black;
    font-size: 12;
    padding-left: 30px;
    text-align: left;
  }

  &__invalid {
    border: 1px solid $status-error;
  }

  &__passwordmask {
    -webkit-text-security: disc;
    -moz-webkit-text-security: disc;
    -moz-text-security: disc;
  }
}
</style>
